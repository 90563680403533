import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import SubpageHero from "../components/subPageHero"
import SubpageWrapper from "../components/subpageWrapper"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = () => (
  <Layout>
    <Seo title="Contact Priority One" />
    <SubpageHero h1="Contact Us" />
    <SubpageWrapper></SubpageWrapper>
  </Layout>
)

export default ContactPage
